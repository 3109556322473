<template>
  <div v-if="!preferencesLoaded">
    <a-col style="marginTop: 17%" :span="3" :offset="10">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 40px" spin />
      </a-spin>
    </a-col>
  </div>
  <div class="user-preferences-container" v-else>
    <a-row style="marginTop: 3.5%">
      <a-col :span="6" :offset="8">
        <a-card :title="$t('users.preferences.title')">
          <a-form>
            <a-row>
              <a-col>
                <a-form-item
                  :label="$t('users.preferences.mapMode.title')"
                  :label-col="labelCol"
                  labelAlign="left"
                >
                  <a-col :push="2">
                    <a-select
                      v-model="preferences.mapMode"
                      style="width: 35%"
                      @change="onChangeMapModePreference"
                    >
                      <a-select-option value="ROADMAP">
                        {{ $t("users.preferences.mapMode.plan") }}
                      </a-select-option>
                      <a-select-option value="SATELLITE">
                        {{ $t("users.preferences.mapMode.satellite") }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item
                  :label="$t('users.preferences.showMap')"
                  :label-col="labelCol"
                  labelAlign="left"
                >
                  <a-col :push="3">
                    <a-switch
                      v-model="preferences.showMap"
                      @change="onChangeShowMapPreference"
                    />
                  </a-col>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item
                  :label="$t('users.preferences.langage.title')"
                  :label-col="labelCol"
                  labelAlign="left"
                >
                  <a-col :push="2">
                    <a-select
                      v-model="preferences.langage"
                      style="width: 35%"
                      @change="onChangeLangagePreference"
                    >
                      <a-select-option value="fr">
                        {{ $t("users.preferences.langage.fr") }}
                      </a-select-option>
                      <a-select-option value="en">
                        {{ $t("users.preferences.langage.en") }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-form-item>
                <a-col
                  :xs="{ span: 14, offset: 2 }"
                  :sm="{ span: 6, offset: 0 }"
                  :md="{ span: 8, offset: 2 }"
                  :xl="{ span: 8, offset: 8 }"
                  :xxl="{ span: 5, offset: 7 }"
                >
                  <a-button
                    @click="() => reset()"
                    class="btn btn-danger"
                    type="danger"
                  >
                    {{ $t("form.reset") }}
                  </a-button>
                </a-col>
                <a-col :xs="14" :sm="6" :md="8" :xl="8" :xxl="8">
                  <a-button @click="() => save()" class="btn" type="primary">
                    {{ $t("form.save") }}
                  </a-button>
                </a-col>
              </a-form-item>
            </a-row>
          </a-form>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Row,
  Col,
  Switch,
  Card,
  Button,
  Icon,
  Form,
  Select,
} from "ant-design-vue";

Vue.use(Row);
Vue.use(Col);
Vue.use(Switch);
Vue.use(Card);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Select);
Vue.use(Form);

export default {
  name: "userPreferences",
  computed: {},
  mounted() {
    if (this.$store.state.preferences.lastFetch === null) {
      this.$store.dispatch("fetchUserPreferences");
    }
    let debounce = null;
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      this.refreshPreferencesFromStore();
      this.preferencesLoaded = true;
    }, 2000);
  },
  data() {
    return {
      preferencesLoaded: false,
      preferences: null,
      labelCol: { span: 8 },
    };
  },
  methods: {
    refreshPreferencesFromStore() {
      this.preferences = this.$store.getters.getUserPreferences();
    },
    onChangeMapModePreference(value) {
      this.preferences.mapMode = value;
    },
    onChangeShowMapPreference(checked) {
      this.preferences.showMap = checked;
    },
    onChangeLangagePreference(value) {
      this.preferences.langage = value;
    },
    onChangeLayout(value) {
      this.preferences.layout = value;
    },
    save() {
      let promise = this.$store.dispatch("setUserPreferences", {
              preferences: this.preferences,
              user: {...this.$store.getters.getCurrentUser()}
            });
      promise.then(() => {
        this.refreshPreferencesFromStore();
        this.$root.$i18n.locale = this.preferences.langage;
      })
    },
    reset() {
      this.refreshPreferencesFromStore();
    },
  },
};
</script>
